import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LineArrowRight from "../Icons/LineArrowRight"
import PrimaryLinkButton from "../Button/PrimaryLinkButton"
import BreakpointUp from "../Media/BreakpointUp"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Card = styled.div`
  position: relative;
  background-color: #fff;
  box-shadow: 0 20px 40px rgba(3, 51, 102, 0.15);
  margin-bottom: 30px;
  border-radius: 6px;
  align-items: center;
  text-align: center;
  height: calc(100% - 30px);
`

const CardFigure = styled.figure`
  position: relative;
  margin: 0;
  overflow: hidden;
  .gatsby-image-wrapper {
    display: block;
    max-height: 200px;
    > div {
      max-width: 100% !important;
    }
  }
`
const CardBody = styled.div`
  position: relative;
  padding: 15px;
  ${BreakpointUp.xl`
    padding:30px;
  `}
  ${BreakpointUp.xxl`
    padding:50px;
  `}
  .badge {
    margin-bottom: 10px;
  }
`
const CardTitle = styled.div`
  color: #000;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  padding: 20px 15px;
  font-size: 20px;
  line-height: 24px;
  @media (min-width: 768px) {
    padding: 25px;
    font-size: 22px;
    line-height: 28px;
  }
  @media (min-width: 1600px) {
    font-size: 24px;
    line-height: 30px;
  }
  strong {
    display: inline-block;
    color: #d51333;
    font-size: 24px;
    line-height: 30px;
    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 36px;
    }
    @media (min-width: 1600px) {
      font-size: 32px;
      line-height: 42px;
    }
  }
  a {
    display: inline-block;
    color: #000;
    &:hover,
    &:focus {
      color: #033366;
    }
  }
`
const CardDesc = styled.div`
  margin-bottom: 15px;
  p {
    @media (min-width: 1600px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
`

const CardToolbar = styled.div`
  .btn {
    text-transform: uppercase;
    font-size: 14px;
  }
`

const RoofCard = ({ title, content, image, url }) => {
  let type = ""
  if (title.indexOf("Vertical") > -1) {
    type = "Best"
  }
  if (title.indexOf("A-Frame") > -1) {
    type = "Better"
  }
  if (title.indexOf("Regular") > -1) {
    type = "Good"
  }
  return (
    <Card className="card roof-card">
      <CardTitle className="card-title">
        <strong>{type}</strong> <Link to={url}>{title}</Link>
      </CardTitle>
      <CardFigure className="card-img">
        <GatsbyImage image={getImage(image)} alt={title} />
      </CardFigure>
      <CardBody className="card-body">
        <CardDesc>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </CardDesc>
        {url && (
          <CardToolbar>
            <Link to={url}>
              <PrimaryLinkButton
                icon={<LineArrowRight />}
                text="View More Detail"
              />
            </Link>
          </CardToolbar>
        )}
      </CardBody>
    </Card>
  )
}

export default RoofCard
