import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  Section,
  SectionDescription,
  SectionPageTitle,
} from "../../components/Section"
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon"
import Badge from "../../components/Badge"
import HandPointer from "../../svg/handpointer.svg"

const Hero = styled.div`
  position: relative;
  padding-top: 60px;
  @media (min-width: 1200px) {
    padding-top: 90px;
  }
  @media (min-width: 1600px) {
    padding-top: 120px;
  }
`
const HeroCaption = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff;
  padding: 0 15px;
`
const CaptionTop = styled.div`
  margin: 0;
  a {
    color: #000;
    font-weight: 700;
    &:hover {
      color: #ec1c24;
    }
  }
`
const CaptionMiddle = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-direction: column-reverse;
  ${BreakpointUp.lg`
		flex-direction: inherit;
	`}
`
const Feature = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  margin-top: -60px;
  ${BreakpointUp.lg`
		margin-top:-100px;
	`}
  ${BreakpointDown.lg`
	 	margin-top:0px;
	`}

	${BreakpointUp.lg`
		flex: 0 0 50%;
   		max-width: 50%;
		margin-top:0;
	`}
	${BreakpointUp.xl`
		flex: 0 0 25%;
   		max-width: 25%;
	`}
	${BreakpointUp.xxl`
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	`}
`
const FeatureBody = styled.div`
  padding: 30px 0;
  ${BreakpointUp.lg`
		padding:30px; 	
	`}
  ${BreakpointUp.lg`
		padding:60px 30px 30px; 	
	`}
	${BreakpointUp.xxl`
		padding:100px 30px 30px; 	
	`}
`
const FeatureTitle = styled.div`
  position: relative;
  display: block;
  margin-bottom: 15px;
  color: #000;
  span {
    display: inline-block;
    position: relative;
    &:after {
      ${BreakpointUp.lg`
				content: "";
				position: absolute;
				left: 100%;
				top: 50%;
				background-image: linear-gradient(to right,transparent 50%,rgb(0,0,0) 0%);
				background-position: 0px 0px;
				background-size: 9px 1px;
				background-repeat: repeat-x;
				height: 2px;
				transform: translateY(-50%);
				margin-top: 3px;
				z-index:1;
				width: 50%;
			`}
    }
  }
`
const ListFeature = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 28px;
  ${BreakpointUp.sm`
		font-size: 16px;
		line-height: 30px;	
	`}
  ${BreakpointDown.lg`
		column-count: 2;
		column-gap: 5px;
	`}
	${BreakpointDown.sm`
	 column-count: 1;
	`}
	& li {
    padding-left: 20px;
    vertical-align: middle;
    margin: 0;
    position: relative;
    + li {
      margin-top: 5px;
      ${BreakpointUp.sm`
				margin-top:10px;
			`}
    }
    &:before {
      content: url(${HandPointer});
      display: inline-block;
      margin-left: -20px;
      width: 30px;
      vertical-align: top;
      height: 30px;
      line-height: 30px;
    }
  }
`
const ListFeatureItem = styled.li`
  margin: 0;
`

const CaptionFigure = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  ${BreakpointUp.xxl`
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	`}
  .gatsby-image-wrapper {
    border-radius: 6px;
    box-shadow: 0 20px 65px rgba(3, 51, 102, 0.2);
    overflow: hidden;
    display: block;
  }
`
const InfoTip = styled.div`
  background-color: ${props => props.bgColor};
  padding: 4px 20px;
  border-radius: 4px 0 4px 0;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: #fff;
`
const CaptionOption = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
		flex: 0 0 25%;
   		max-width: 25%;
	`}
  display:none;
  ${BreakpointUp.xl`
		display:block;
	`}
  ${BreakpointUp.xxl`
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	`}
`
const CaptionOptionBody = styled.div`
  padding: 60px 30px 30px;
  ${BreakpointUp.xxl`
		padding:100px 30px 30px; 	
	`}
`
const CaptionOptionTitle = styled.div`
  position: relative;
  display: block;
  margin-bottom: 20px;
  color: #000;
  text-align: left;
  span {
    display: inline-block;
    position: relative;
    max-width: 150px;
    &:after {
      content: "";
      position: absolute;
      right: 100%;
      top: 50%;
      background-image: linear-gradient(
        to right,
        transparent 50%,
        rgb(0, 0, 0) 0%
      );
      background-position: 0px 0px;
      background-size: 9px 1px;
      background-repeat: repeat-x;
      height: 2px;
      transform: translateY(-50%);
      margin-top: 3px;
      z-index: 1;
      width: 80%;
    }
  }
`
const BadgeVertical = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
`
const BadgeVerticalItem = styled.li`
  margin: 0;
  padding: 0;
  > a {
    display: inline-block;
    max-width: 202px;
    width: 100%;
    .icon {
      svg {
        fill: #eba300;
      }
    }
    &:hover,
    &:focus {
      .badge {
        background-color: #eba300;
        color: #fff;
        .icon {
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
  + li {
    margin-top: 12px;
  }
`
const HeroCategorySection = props => {
  const { title, description } = props
  return (
    <Section pt="60px" pb="0">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <Hero>
          <HeroCaption>
            <CaptionTop>
              <SectionPageTitle mb="15px">{title}</SectionPageTitle>
              <SectionDescription maxWidth="1496px">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </SectionDescription>
            </CaptionTop>
            <CaptionMiddle>
              <Feature>
                <FeatureBody>
                  <FeatureTitle className="h5">
                    <span>Providing Features:</span>
                  </FeatureTitle>
                  <ListFeature>
                    <ListFeatureItem>Customization</ListFeatureItem>
                    <ListFeatureItem>Durability</ListFeatureItem>
                    <ListFeatureItem>Flexibility & Versatility</ListFeatureItem>
                    <ListFeatureItem>Low Maintenance</ListFeatureItem>
                    <ListFeatureItem>Easy & Quick Assembly</ListFeatureItem>
                    <ListFeatureItem>Cost-Efficiency</ListFeatureItem>
                    <ListFeatureItem>Many Choices</ListFeatureItem>
                  </ListFeature>
                </FeatureBody>
              </Feature>
              <CaptionFigure>
                <InfoTip bgColor="#D51333">Best</InfoTip>
                <StaticImage
                  src="../../images/metal-garage.jpg"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="metal-garage"
                />
              </CaptionFigure>
              <CaptionOption>
                <CaptionOptionBody>
                  <CaptionOptionTitle className="h5">
                    <span>Garages Roof Style Options </span>
                  </CaptionOptionTitle>
                  <BadgeVertical>
                    <BadgeVerticalItem>
                      <Link to="/">
                        <Badge
                          text="Vertical Roof Style"
                          icon={<ArrowRightIcon />}
                        />
                      </Link>
                    </BadgeVerticalItem>
                    <BadgeVerticalItem>
                      <Link to="/">
                        <Badge
                          text="A-Frame Roof Style"
                          icon={<ArrowRightIcon />}
                        />
                      </Link>
                    </BadgeVerticalItem>
                    <BadgeVerticalItem>
                      <Link to="/">
                        <Badge
                          text="Regular Roof Style"
                          icon={<ArrowRightIcon />}
                        />
                      </Link>
                    </BadgeVerticalItem>
                  </BadgeVertical>
                </CaptionOptionBody>
              </CaptionOption>
            </CaptionMiddle>
          </HeroCaption>
        </Hero>
      </Container>
    </Section>
  )
}

export default HeroCategorySection
