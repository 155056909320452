import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryOutlineBtn = styled(BaseButton)`		
	background-color:transparent;
    border-color:#033366;
	color:#033366;	
	& .icon{
		> svg{
			fill:#033366;
		}
	}
	&:hover{
        color:#fff;	
		background-color:#033366;
        border-color:#033366;
        & .icon{
            > svg{
                fill:#fff;
            }
        }
	}

`

const PrimaryOutlineButton = (props) => {
	const { icon, text, size } = props;
	return(
		<PrimaryOutlineBtn className={`btn ${size ? `btn-${size}` : ''} `}>
            <>
                { text &&
                    <span className='text'>{text}</span>
                }
                { icon &&
                    <span className='icon'>{icon}</span>
                }
            </>
		</PrimaryOutlineBtn>
	)
}

export default PrimaryOutlineButton