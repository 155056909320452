import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'
import BreakpointDown from "../Media/BreakpointDown"

export const AccordionLabel= styled.h2`
  padding:0 15px;
  ${BreakpointUp.md`
    padding:0 40px;
  `}
  ${BreakpointUp.xl`
    padding:0 90px;
  `}
`
export const AccordionDesc= styled.div`
  padding:0 15px;
  ${BreakpointUp.md`
    padding:0 40px;
  `}
  ${BreakpointUp.xl`
    padding:0 90px;
  `}
`
export const Accordions = styled.div`
  margin:0;
`
export const Accordionsitems = styled.div`
  position:relative;
  padding:0 15px;
  ${BreakpointUp.md`
    padding:0 40px;
  `}
  ${BreakpointUp.xl`
    padding:0 90px;
  `}

  &+&{
    border-top:1px #CCD6E0 solid;
  }

  &.active{
    span{
      transform:rotate(45deg);
      color:#000;
      &:before,
      &:after{
        background:#000;
      }
    }
    .accordions-content{
      max-height:1000px;
      overflow:inherit;
      transition: max-height 0.2s ease-out;
    }
    &:before{
      opacity:1;
    }
  }

  &:before{
    display:block;
    content:'';
    position:absolute;
    left:-3.1%;
    right:-3.2%;  
    background:#fff;
    top:0;
    bottom:0;
    box-shadow: 0px 25px 55px #03336633;
    padding:20px;
    opacity:0;
    transition: max-height .35s ease;
    ${BreakpointDown.md`
      display:none;
    `}
  }
`
export const AccordionsContent = styled.div`
  max-height:0;
  overflow:hidden;
  transition: max-height .35s ease;
  position: relative;
  letter-spacing: 0.18px;
  color:#666;
`
export const AccordionsContentinner = styled.div`
  position:relative;
  z-index:2;
  padding:0px 0 20px;
`
export const AccordionsTitle = styled.div`
  cursor: pointer;
  display:flex;
  letter-spacing: 0.56px;
  align-items:center;
  margin-bottom:0;
  justify-content:space-between;
  z-index:1;
  position:relative;
  color: #000;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding:20px 0;
  ${BreakpointUp.md`
    padding:40px 0;
    font-size: 22px;
    line-height: 28px;
  `}
  ${BreakpointUp.xxl`
    font-size: 24px;
    line-height: 30px;
  `}

  span{
    flex: 0 0 14px;
    height:14px; 
    position:relative;
    display:inline-block;
    transition: all .35s ease;
    margin-left:15px;
    &:before,
    &:after{
      display:block;
      content:'';
      position:absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      background:#D51333;
    }
    &:before{
      width:3px;
      height:100%;
    }
    &:after{
      width:100%;
      height:3px;
    }
  }
`