import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PrimaryOutlineButton from "../../components/Button/PrimaryOutlineButton"
import LineArrowRight from "../../components/Icons/LineArrowRight"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  SectionInnerWhite,
} from "../../components/Section"
import ProductCardList from "../../components/ProductCardList"

const ExploreMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: -32px;
  width: 100%;
  > a {
    width: 100%;
    max-width: 340px;
    .btn {
      width: 100%;
      background-color: #fff;
      &:hover {
        background-color: #033366;
      }
    }
  }
`

const ProductSection = props => {
  const { title, description, data } = props
  return (
    <Section
      xpt="100px"
      mpt="60px"
      pt="30px"
      xpb="100px"
      pb="60px"
      bgColor="#F5FAFF"
      bdrWidth="1px"
      bdrColor="#CCD6E0"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite>
          <SectionTitle>{title}</SectionTitle>
          <SectionDescription maxWidth="1496px" mb="40px">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </SectionDescription>
          <ProductCardList data={data} />
          <ExploreMore>
            <Link to="/">
              <PrimaryOutlineButton
                text="Explore Our All Products"
                icon={<LineArrowRight />}
                size="lg"
              />
            </Link>
          </ExploreMore>
        </SectionInnerWhite>
      </Container>
    </Section>
  )
}

export default ProductSection
