import React, { useState } from "react"
import styled from 'styled-components'
import Layout from "../layouts"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { Section, SectionTitle, SectionDescription, Container, BreadCrumb, MarkdownContent, MarkdownBody, MarkDownRow, MarkDownLeft, MarkDownRight, MarkDownTwocol, SectionInnerWhite } from "../components/Section"
import { SectionExpert, SectionToolbar, ExpertBackground, SectionExpertContent } from "../components/CallExpert"
import { AccordionLabel, AccordionDesc, Accordions, Accordionsitems, AccordionsContent, AccordionsContentinner, AccordionsTitle } from "../components/Accordion"
import HeroCategorySection from "../sections/Product/HeroCategorySection"
import ProductCategorySection from "../sections/Product/ProductCategorySection"
import RoofCard from "../components/RoofCard"
import BreakpointUp from "../components/Media/BreakpointUp"
import PhoneIcon from "../components/Icons/PhoneIcon"
import PrimaryButton from "../components/Button/PrimaryButton"
import { StaticImage } from "gatsby-plugin-image"

const SectionGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin:0 -10px;
`
const SectionGridCol = styled.div`
    position: relative;
    width:100%;
    padding:0 10px;
    justify-content: center;
    ${BreakpointUp.lg` 
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
`


const MetalBuildingPage = ({ location, data }) => {
    const [activeTab, setActiveTab] = useState(null)
    function handleAccodion(key) {
        if (activeTab === key) {
        return setActiveTab(null)
        }
        setActiveTab(key);
    }
    return (
    <Layout isHeader={true} location={location}>
        <Seo title="Metal Buildings" />
        <BreadCrumb>
            <Container maxWidth="100%" pl="3%" pr="3%">
                <Link to="/">Home </Link><span>Metal Buildings</span>
            </Container>
        </BreadCrumb>
        <HeroCategorySection title="Metal Buildings" description={`<p>Welcome to the incredibly versatile world of metal buildings! The term, “metal building”, encompasses everything from prefab buildings to metal building kits to custom metal buildings. According to the 2014 Annual Report of the Metal Building Manufacturer’s Association, “Metal building systems now account for over 50% of the new, low-rise, non-residential buildings in the U.S.”.</p><p>Metal structures in the modern era are so much more than a backyard shed or barn. You can order the perfect house or design your dream workshop! Your company can purchase the most efficient warehouse, or your organization can finally own the flex space it needs. With a variety of customizable options, metal buildings are the beautiful, environmentally responsible choice for every consumer!</p>`}/>
        <ProductCategorySection title="Discover Your Dream Metal Building" description={`<p>We can’t wait to share the incredible versatility, durability, and affordability of metal buildings with you! There are so many construction options available; why should you do business with Liberty Metal Buildings? Because we offer the best in design, customized options, and customer service.</p>`} data={data.allContentfulProduct.edges} />
        <Section xpt="100px" mpt="60px" pt="60px" pb="0" bgColor="#F0F8FF" >
            <Container maxWidth="100%" pl="3%" pr="3%">
                <SectionTitle mb="10px">Span of Steel : Roof Styles</SectionTitle>
                <SectionDescription maxWidth="1496px" mb="40px">
                    <p>There are so many decisions to be made when designing your custom building. Fortunately, finding a practical and affordable roof is simple! Depending on your location and weather, the roof design can be adjusted to serve you. Read on to learn about the different styles and benefits of each roof. As always, our experts are happy to answer any other questions you may have!</p>
                </SectionDescription>
                <SectionGrid>
                    {[
                        {
                            type: 'Best',
                            title: 'Vertical Roof',
                            content: `The vertical roof is, by far, the strongest of the options. Because this roof is engineered with cross braces, ridge caps, and a hat channel, it has the mettle (and metal!) to withstand any weather. These reinforcements make the vertical roof our experts’ recommendation for areas that see severe storms and high winds. The panels are installed vertically, which encourages snow, rain, and debris toward the ground. This makes maintenance a breeze!`

                        },
                        {
                            type: 'Better',
                            title: 'A-Frame Roof Style',
                            content: `Also known as “boxed-eave”, the A-frame roof is less expensive than the vertical roof, but still recommended for areas with high winds. The panels are installed horizontally, however, so snow may pile up. Experts do not recommend the A-frame for use in locations where there may be excessive rain or snow loads. Still, this roof is durable and long-lasting, like all quality products from Liberty Metal Buildings. As it is only a little more expensive than the standard, regular roof, you may want to upgrade to this beautiful, stylish option.`

                        },
                        {
                            type: 'Good',
                            title: 'Regular Roof',
                            content: `The basic, curved edge roof option at Liberty Metal Buildings is best for mild and temperate weather zones. These are designed without trim, ridge cap, or hat channel, and are, therefore, less expensive than more reinforced designs. The steel panels are placed horizontally and recommended for light and moderate inclement weather.`

                        }
                    ].map((item, i) =>
                        <SectionGridCol key={i}><RoofCard type={item.type} title={item.title} content={item.content} /></SectionGridCol>
                    )}
                </SectionGrid>
            </Container>
        </Section>

        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Quality Steel Buildings</h2>
                        <p>Our high-quality steel is the best material on the market! Not only is it fire-resistant and non-corrosive, steel is 100% recyclable! Steel structures cost 30% less, on average, than their traditional brick and mortar counterparts. More importantly, they are environmentally friendly, incredibly durable in inclement weather, and a long-lasting investment! Find out more about the different types of metal buildings below:</p>

                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Agricultural Metal Buildings</h3>
                                <p>Your idyllic barn is only a few clicks away! Steel buildings are an excellent choice because they are beautiful, fire-resistant, and extremely durable. Whether you decide on a drop-down roof or an enclosed frame, the customizable options at Liberty Mutual Structures will assist you in creating the perfect agricultural building for your farm. Need an open, indoor space for your horses? Simple! Do you want a single side enclosed to protect your equipment from extreme weather? Easily done.</p>       
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Commercial Metal Buildings</h3>
                                <p>Your business deserves the best commercial space to accommodate growth and efficiency. If you’re tired of trying to find a crumbling old building that “might work” for your enterprise, it’s time to read about the versatility of commercial metal buildings! Our clear span structures offer incredible space and flexibility. Even better, they are far more cost-effective than ordinary timber buildings. The steel roof is rated for wind and snow – our buildings can handle whatever Mother Nature throws at them!</p>       
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Industrial Metal Buildings</h3>
                                <p>Your industrial metal building must be safe and tough. Look no further than the metal buildings at Liberty Metal Buildings! Our fire-resistant steel frames and non-corrosive metal siding are the perfect solutions to your industrial needs. You can order a prefabricated metal building or design one with dimensions that accommodate your equipment. Your employees will be grateful for the sturdy and airy design of our gorgeous, clear span buildings!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Residential Metal Buildings</h3>
                                <p>Your home is your castle; why shouldn’t it be an environmentally and affordable one? A traditional stick-built house, purchased in 2020, would cost approximately $264,000 in the United States. That same house can be created with metal for 30% less. Metal buildings are more energy-efficient than their timber competitors and require less insulation. Who doesn’t want to save money on energy bills?</p>
                                <p>Unlike other materials, steel releases no toxic fumes and requires no dangerous chemical processing during production. Even better, a steel frame is 100% recyclable. Your residential metal building is your gift to the environment!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Storage Metal Buildings</h3>
                                <p>Everyone needs storage. The self-storage industry was valued at $48 billion in 2020, and is expected to by $64 billion by 2026. But why should you pay someone else for their storage unit when you can easily and affordably build your own?</p>
                                <p>Storage metal buildings from Liberty Metal Buildings are durable enough to withstand all kinds of weather. You deserve the best, and so does your storage.</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />                        
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Clear Span Metal Buildings</h3>
                                <p>Two words: Flex Space. The best feature of our clear span metal buildings is the incredible versatility offered by a structure that doesn’t necessarily require interior columns or walls. You can create a gym, an arena, a studio, or the free-flow workspace of your dreams in one of the clear span buildings from Liberty Metal Buildings!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Custom Metal Buildings</h3>
                                <p>At Liberty Metal Buildings, our customers come first! We offer an endless variety of options, so you can create your building just the way you want it. Our experts are happy to coordinate the custom options for your building with you and calculate their weights into the building schematic! Whatever you’re dreaming of, our fantastic team can make it happen!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>     
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Popular Models of Metal Buildings</h2>
                        <p>A metal building is so much more than a backyard shed these days. Everything from the latest in commercial warehouses to your next stylish guesthouse is available in our lineup of beautiful metal structures. Discover the popular shapes and sizes available from Liberty!!</p>

                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>60×60 Metal Garage</h3>
                                <p>Our 60’x60’ fully enclosed garage features all vertical paneling, four 10’ x 10’ garage doors, and two standard 36” x 80” doors. With so much clear span space, your imagination is the only limitation in this beautiful building!</p>    
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>40×80 Building</h3>
                                <p>This fully enclosed metal structure utilizes fire-resistant and eco-friendly steel. It features our highest rated, vertical roof, two 12’x12’ roll-up garage doors, and a standard 36”x80” door. Its incredibly wide design will accommodate your RV, your farm machinery, your camper, your ATVs, or any other equipment you want to maintain in a safe space!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>24×60 Workshop Building</h3>
                                <p>Walk across the 20’ porch, and welcome to our beautiful workshop! You’ll find two 10’x8’ roll up garage doors, and three 30”x30” windows. The 40’ of enclosed area is all yours, and you can feel secure in the knowledge that the highly rated roof will keep everything within your workshop safe!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />                        
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>28×70 Metal Building</h3>
                                <p>Our 28’ x 70’ metal building is a fully enclosed structure with so much to offer! More than the 36” x 80” standard door, this beauty features two 12’ x 12’ roll of garage doors for your equipment and vehicles.  Remember, with our brilliant clear span designs, there are no pesky columns to get in the way of your workspace!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />                        
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>30×70 Custom Metal Building</h3>
                                <p>This beautiful, custom structure is one of our most popular options! With a wide availability of uses, it is begging to become your new favorite workspace! From the patio to the storage, from the open span space to the enclosed shop, this building is ready for anything you can throw at it. With a certification for 140mph winds and a 35psf snow load, it’s ready for anything Mother Nature has to offer, too!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr /> 
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>24×70 Metal Storage Workshop</h3>
                                <p>The metal storage workshop from Liberty Metal Buildings features a gorgeous A-frame roof with vertical paneling, meaning it’s highly rated for snow or wind. Fire-resistant and non-corrosive steel make this building the perfect choice for the serious workshop and you!</p>
                                <p>For assistance designing your custom metal building, don’t hesitate to call one of the experts at Liberty Metal Buildings. Our customers come first! We’ll be happy to provide you with a quick quote, expert advice, delivery options, installation requirements, and more!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr /> 
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>30x50 Commercial</h3>
                                <p>See what a metal building can do for your business by touring our 30’x50’ commercial structure! Clear span design grants you the flexibility to take your enterprise to the next level. Don’t forget to mention to your customers that metal construction is environmentally friendly and energy efficient!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr /> 
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>30×50 Farm Building</h3>
                                <p>What a steel! Our metal farm buildings are the non-toxic, eco-friendly choice for your farm! With flexible space, customizable options, and a roof rated to withstand inclement weather, you can’t afford to go back to traditional construction. We get your product to you as you want it, when you need it!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr /> 
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>48x36 Metal Storage Building</h3>
                                <p>You deserve the best quality and value when it comes to your storage needs. We know that your equipment, your crops, and your belongings are important! Steel is a non-corrosive, fire-resistant material that you can depend on. High winds and heavy snows are no match for our reinforced steel roofs and expertly engineered design.</p>

                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>
                        <hr />                         
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>40x80 Industrial</h3>
                                <p>The 40 x 80 Industrial Building is the behemoth of our structure family. Customize yours with numerous garage doors and walkouts, or line a whole wall with windows for natural lighting! Whatever you plan for this incredibly durable structure, you know that the clear span design will grant the flexibility you need. </p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />
                        <p>For assistance designing your custom metal building, don’t hesitate to call one of the experts at <a href="tel:8004407309">(800) 440-7309</a>. Our customers come first! We’ll be happy to provide you with a quick quote, expert advice, delivery options, installation requirements, and more!</p>
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Metal Health: Did You Know? </h2>

                        <h3>Did you know that metal buildings improve indoor air quality?</h3>
                        <p>Organic materials, such as wood and fiberboard, decompose over time and will release particulates into the air. These decaying byproducts are known to be irritants to the respiratory system and can lead to a polluted indoor atmosphere. Metal buildings are not susceptible to these problems!</p>
                        <hr />

                        <h3>Did you know that steel is formaldehyde-free?</h3>
                        <p>The Leadership in Energy and Environmental Design (LEED) green building certification program recommends steel for its non-toxic qualities. Steel is not treated with formaldehyde, so it won’t release VOCs into the air over time, like other construction materials.</p>
                        <hr />

                        <h3>Did you know you that steel won’t produce allergens or mold spores?</h3>
                        <p>The professionally sealed environment of a metal building is the enemy of mildew! Without moisture from leaks and without wood framing to feast on, allergens and mold can’t survive in your new steel structure. For anyone with allergies or asthma, this is a cause for celebration!</p>
                        <hr />

                        <h3>Did you know that metal buildings save you money on toxic pest control treatments?</h3>
                        <p>What’s a termite to eat around here? Not your freshly installed metal building, that’s for sure! Traditional wood buildings are treated with harmful chemicals to deter bugs, but those same chemicals are dangerous for you! Steel won’t require any of those nasty treatments.</p>
                        <hr />
                        
                        <h3>Did you know it gives you protection from noise pollution?</h3>
                        <p>You might think that a metal building will amplify noise, but it will actually provide a better environment for quiet! Because of the wide cavity framing in metal building exterior walls, there is more insulating space. This creates a more effective sound barrier between you and the outside world. If your busy, bustling neighbourhood gives you a headache, you may want a metal building for your own peace of mind.</p>
                        <hr />

                        <h3>Did you know you are creating a greener world?</h3>
                        <p>Let’s save the planet, one tree at a time. Steel is 100% recyclable, lasts longer, and has proven to be more energy efficient. Allow our trees to promote carbon storage, oxygen production, and environmental cooling; leave the construction up to high-grade metal!</p>
                        <hr />

                        <h3>Did you know that choosing what’s best for the planet is what’s best for you?</h3>
                        <p>Metal buildings are built for sustainability, with recyclable resources and low-maintenance goals. Our world is changing, and energy-efficiency is becoming more important than ever. As we all look for ways to reduce our dependence on fossil fuels, we are committing to long-term air and water quality. We want to leave the world a better place than we found it. Good news! Metal buildings are dependable, versatile, and environmentally friendly. </p>
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>BE PREPARED: Site Preparation & Installation</h2>
                        <p>Liberty Metal Buildings offers free delivery and installation on every quality building in our inventory, but your building site must be properly prepared before delivery. You may need to consult with soil engineers in order to find the best site.</p>

                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Survey the Site</h3>
                                <p>Our experts recommend that you find a site that is slightly elevated, for drainage purposes. Choose a spot for your metal building away from trees and other structures, with enough space for you and your crew to install with ease</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Read the Rules </h3>
                                <p>Don’t break ground without consulting your local planning office! Depending on your municipality’s codes, your building may have to have a soil survey or an engineer’s approval of the building plan. You might even need to post a permit at the construction site! Whatever the ordinance requires, it is there to keep you, your building, and your crew safe. </p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Ditch the Debris</h3>
                                <p>Your crew can’t begin if there’s grass and debris on site. Some companies will pack up and charge a return fee! Before installation day, be sure every bit of brush and detritus are removed.</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Soil Stability</h3>
                                <p>Is your water table high? What’s the loam content in your soil? Is your area mostly sand or clay? These are questions for a soil engineer, a professional who will specialize in preparing the ground for your foundation and building. Their professional evaluation and recommendation will be vital to the long-term health and structural integrity of your building, so be sure to have them visit the site before scheduling installation!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Find Your Foundation</h3>
                                <p>Your building’s foundation is an essential component of its overall integrity. You’ll need to have the foundation completed before installation can begin, so let’s jump straight into the options you can choose from! The most popular and durable option is concrete; you’ll find that concrete requires no upkeep and provides the most long-term stability for your building. Our experts recommend that you have a professional install concrete so that environmental factors are accounted for, and you get the highest quality pour and seal before installation.</p>
                                <p>If you want a slightly cheaper option, you can go with asphalt, but it will require yearly sealant. Some customers prefer the budget option of gravel, which can work short-term and is able to be upgraded to concrete in the future. If you have compacted and professionally surveyed soil, some ground foundation can work.</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />     
                        <p>There is a lot to consider when preparing to install your metal building, but Liberty Metal Buildings is here to help! Whatever the question, our friendly experts are happy to assist you. Call today at <a href="tel:8004407309">(800) 440-7309</a>, and discover why our customers are the happiest in the business! </p>
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Superior Steel</h2>
                        <p>Welcome to the incredible world of metal buildings! Allow us to relate the endless benefits of owning a metal structure. You will be amazed that anyone still uses the old stick-built method once you’ve learned that steel construction is cheaper, faster, and more reliable!</p>
                        <hr />
                        <h3>Durable & Dependable </h3>
                        <p>Metal buildings are quickly becoming the standard for tough, quality craftsmanship in the construction industry. Liberty Metal Buildings is so confident in the long-lasting durability of our metal buildings that we back each structure with a twenty-year rust-through warranty.</p>
                        <hr />
                        <h3>Eco- friendly & Energy Efficient </h3>
                        <p>100% recyclable steel is the environmentally responsible choice for every discerning consumer. We take pride in our low carbon footprint and our commitment to energy-efficient design. Because our buildings are expertly sealed at installation, you can expect metal buildings to have far lower energy costs than a traditional wood building.</p>
                        <hr />
                        <h3>Customizable </h3>
                        <p>Have it your way! At Liberty Metal Buildings, we are committed to total customer satisfaction! While we are certain you will find a building you love in our inventory, we also offer a wide range of colors, roof styles, and window and door options. We won’t be happy until you are happy, so call one of our friendly experts today at <a href="tel:8004407309">(800) 440-7309</a> and allow us to create a building exactly the way you want it!</p>
                        <hr />
                        <h3>Threat Resistant</h3>
                        <p>Safety is a priority in every decision you make as a consumer. In construction, steel is known for its fire-resistant properties. Because it is non-corrosive and created without toxic chemicals, you never need to worry about harmful particulates leeching into the air. Even better, steel is mold and mildew resistant. For residents with allergies or asthma, the long term improved air quality can be invaluable!</p>
                        <hr />
                        <h3>Low Maintenance</h3>
                        <p>If you’ve ever spent time sealing a deck or repairing shingles, you know how expensive and time consuming it can be to maintain a traditional wood structure. It’s time you experienced the joy of a metal building, where a ten-year warranty will guarantee every steel panel! Snow and debris will simply slide off a vertical metal roof, and storm damage can be buffed out of galvanized metal. Install and relax with Liberty Metal Buildings.</p>
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Multipurpose Metal Buildings</h2>
                        <p>It’s time for you to have the expertly designed workshop, the commercial gym for your next venture, the unique “she shed” of your dreams, or reliable storage for your treasured belongings. Whatever you need, Liberty Metal Buildings has a versatile, clear span metal building for you! </p>
                        <h2>Endless Possibilities:</h2>
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Storage</h3>
                                <p>Your beloved photo albums and sports memorabilia are important to you. You need a space that you can trust to keep mold and mildew away. Expertly sealed metal buildings are the perfect solution for climate-controlled storage space; that’s why you see steel compartments in most commercial storage facilities. Don’t entrust your treasures to anything less than the best – choose Liberty Metal Buildings!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />     
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Farm</h3> 
                                <p>Your barn must be as tough as you are, and ready to handle anything! You may need extra doors for the livestock, enclosed space for equipment, or well-sealed storage for crops. You can add a lean-to for open-air workspace or widen your building to house extra vehicles and equipment. Whatever your agricultural needs, your metal buildings can be customized to you!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />     
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>As office space</h3>
                                <p>Flexibility is the key to efficient office space! Our clear span metal structures offer you the ability to fully customize your floorplan and redesign it as your office grows! Whether you want a series of cubicles or an open floor plan with a flow of creativity, our metal buildings allow you to experiment with your office space until you find the perfect setup for you and your employees. </p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />     
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>As parking space</h3>
                                <p>A vehicle is forecasted to be one of the biggest expenses in your budget; shouldn’t it be protected from the weather? Extreme temperatures and storms can damage the exterior paint on your car or truck. A metal building, with expert weather ratings, will protect your investments. Have you ever struggled to unload groceries in the pouring rain? Wouldn’t you prefer to enter and exit your vehicle in a warm and dry space?  Metal buildings from Liberty Metal are affordable and spacious!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />     
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Your Unique Space</h3>
                                <p>If your crafts and projects are taking over your living space, it’s time to create the yoga studio or workshop that you deserve! Think of all the ways you could use a flexible, energy-efficient space – an exercise room, a sewing nook, a woodworking shop, etc. Your passions shouldn’t be a frustration, cramped in your house. Purchase a metal building today!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Design Your Dream Building</h2>
                        <p>Customize your colors and personalize your preferences! Liberty Metal Buildings is committed to customer satisfaction, and we offer the options to prove it! If you want ample lighting, an extra door, or a color that will complement your house, we can fashion a metal building exactly the way you want it!</p>
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Sizes</h3>
                                <p>Whatever your dimensions, you’ll find that Liberty Metal Buildings can accommodate you. From our 24 x 60 Workshop to our 40 x 80 Industrial Building, we’ve got every kind of metal building. Get a three-car garage or a sleek shade for your RV. We have got you covered, literally!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />   
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Colors</h3>
                                <p> Choose your colors with the assurance that comes from a twenty-year rust-through warranty! Complement your house with an array of beautiful color and trim options, or choose a shade that will contrast with your favorite truck.  We have over fifteen different colors for you to consider!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />   
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Panels, doors, and windows</h3>
                                <p> Do you like steel paneling exterior? Do you need extra entries and exits? Perhaps, you require a few more windows for natural lighting. Remember, your satisfaction is our priority. These customized options are simple with Liberty Metal Buildings.</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />   
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Trims and anchors</h3>
                                <p> Look closely at the gables featured in our photos (a gable is a triangular piece of steel installed at the top of the structure to conceal the frame and provide stability). Do you like the appearance? What about the decorative J-trim, which is used to cover the edges of the steel frame? If these trim options appeal to you, be sure to let your customer representative know, so they can be placed in your custom package!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />   
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Gauges</h3>
                                <p> Most metal buildings are crafted with standard 14 gauge steel (2.5” x 2.5”). While this provides enough stability for most environments, certain areas which experience high winds, extreme weather, and a lot of snow may want a more durable frame. In such cases, our experts recommend upgrading to 12 gauge steel, which measures 2.25”x2.25”. If you are unsure which is best for your structure, our friendly experts are happy to assist you!</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr /> 
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Roof Style and Location</h3>
                                <p> There are so many decisions to make! Fortunately, there are only three roof styles to choose from. The regular, curved-edge roof is only available for the mildest climates. The reinforced A-frame, or “boxed-eave”, is more suitable for moderate weather areas because it can withstand high winds. The vertical roof, engineered with vertical roof panels, is the strongest, rated for both wind and snow. It’s durable enough to withstand even the stormiest conditions.</p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow> 
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Be Your Own Architect!</h2>
                        <p>No one knows what you want better than you – create the building you desire with our 3D visualizer! With this game-changing tool, you can customize dimensions, doors, and windows. Try out different roof styles and colors. More importantly, see how these options affect your final cost estimate in real time. Be the architect of your own custom building. Try our incredible 3D visualizer today!</p>

                        <hr />

                        <h2>Certified Metal Buildings</h2>
                        <p>Our experts always recommend purchasing a certified metal building. When you are working with a tight budget, it may be tempting to consider cutting corners and working with a manufacturer who will produce a non-certified structure. However, this will only cause more headaches in the future. For one thing, when it comes time for you to sell the property, you may be unable to secure permission from the lender because of uncertified structures on the property. For another, non-certified buildings will not be provided warranties in the same manner as a certified metal building. Certified buildings are reinforced and rated for wind speed and snow loads.</p>

                        <hr />

                        <h2>In the Zone: Building Codes & Permits</h2>
                        <p>Depending on your location and climate, your municipality will have building codes that differ from other areas. Some townships have limits on height and size, or expectations about materials and disposal. It’s possible for your HOA or city to have regulations on dimensions or color, so be sure to do your research before purchase. You can begin with a simple web search or call your local building office for details.</p>

                        <p>Because local regulations have been written by experts on soil, water, and weather, you shouldn’t begin any kind of building project without consulting your zoning office. Some offices will ask for a copy of your building plans and may require that your permit be posted at the construction site. Depending on your state’s hurricane activity or blizzard conditions, you may need a particular roof, approved materials, or extra anchors. Some reinforcements will be required or suggested by construction engineers, and you should take their recommendations seriously. All this red tape can feel like a hassle, but these safety precautions are essential to maintaining the long-term structural integrity of your metal building.</p>

                        <hr />

                        <h2>Liberty Metal Buildings: The Best Prices Anywhere</h2>
                        <p>Liberty Metal Buildings offers the most fantastic pricing in the metal building industry! We offer accurate lead times and quality workmanship, backed by our twenty-year rust-through warranty. You won’t find better deals anywhere on the steel structure of your dreams!</p>

                        <p>Call now at <a href="tel:8004407309">(800) 440-7309</a>, and talk about your custom design with one of our friendly customer service experts! They will be happy to discuss your building plans and explain how custom options will fit your budget. At Liberty, we aren’t just building a building – we are building a relationship! </p>


                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>Call Now! We’ll Find the Financing for You!</h2>
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Rent to Own</h3>
                                <p>At Liberty Metal Buildings, we won’t run your credit! We will approve you within hours, and walk you through our simple, rent-to-own process. You can enjoy a low, monthly rent with a flexible pay off. Our friendly experts will find the plan that works for you! We offer free delivery and installation, plus, extended warranties on our workmanship and materials. Remember, we won’t be satisfied until you are satisfied. Our customers are our priority!</p>
                                <p>Call now at <a href="tel:8004407309">(800) 440-7309</a>, and find out more about our flexible financing options! </p>
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                        <hr />  
                        <MarkDownRow>
                            <MarkDownLeft>
                                <h3>Financing</h3>
                                <p>We are committed to customer service! From foundation to financing, our policy is ultimate customer satisfaction. We will approve your application within a day at Liberty Metal Buildings. While we work to find the perfect financing for you, you can schedule free delivery and installation! Rest assured that our extended warranties reflect the pride and confidence we have in every steel panel. When you have the financing you deserve and we set that delivery date, you can count on the most accurate lead times in the industry.</p>
                                <p>What are you waiting for? Call today at <a href="tel:8004407309">(800) 440-7309</a>!</p>                                
                            </MarkDownLeft>
                            <MarkDownRight>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownRight>
                        </MarkDownRow>                        
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>
        <Section xpt="30px" mpt="20px" pt="15px" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <MarkdownBody>
                    <MarkdownContent maxWidth="1496px">
                        <h2>DIY: Liberty Metal Building Kits</h2>
                        <MarkDownRow>
                            <MarkDownTwocol>
                                <p><strong>It’s the ultimate DIY project – fashioning your custom building!</strong> The prefabricated metal building kits from Liberty Metal Buildings will make installation a breeze! Every well-supplied kit comes with:</p>
                                <ul>
                                    <li>Steel frame (and any supporting reinforcements)</li>
                                    <li>Steel roof panels</li>
                                    <li>Steel wall panels (with your chosen custom color)</li>
                                    <li>Required hardware (fasteners)</li>
                                    <li>Required anchors</li>
                                    <li>Doors & windows</li>
                                </ul>
                                <p>Utilizing a kit means that you control the schedule! Why wait on a crew when you can do it yourself? Kits are usually discounted over other orders, so browse the inventory for a design that works well for you and grab your tools! </p>      
                            </MarkDownTwocol>
                            <MarkDownTwocol>
                                <StaticImage
                                    src="../images/aframe-roof-style-big.jpg"
                                    placeholder="blurred"
                                    alt="aframe-roof-style"
                                />    
                            </MarkDownTwocol>
                        </MarkDownRow>                       
                    </MarkdownContent>
                </MarkdownBody>
            </Container>
        </Section>

        <Section xpt="30px" mpt="20px" pt="15px"  xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <SectionExpert>
                    <ExpertBackground>
                        <StaticImage
                        src="../images/expert-bg.jpg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Expert"
                        />	
                    </ExpertBackground>
                    <SectionExpertContent>
                        <SectionTitle color="#fff" maxWidth="1200px">Choose Liberty!</SectionTitle>
                        <SectionDescription color="#fff" maxWidth="1200px" mb="30px">
                            <p>No one is more committed to their customers than Liberty Metal Buildings; from the first floorplan to the final panel, you will see the difference! Our experts are always standing by to answer your questions, discuss your personalized needs, and assist you with achieving your goal of a custom metal building. We want to build more than a building; we want to build a relationship! We prove our commitment with extended warranties, free delivery, and simple financing. It’s easy to see why our customers are the happiest in the business! Call us today to order your dream metal building.</p>
                        </SectionDescription>
                        <SectionToolbar>
                        <a href="tel:8004407309" aria-label="Phone"><PrimaryButton textAfter="(800) 440-7309"  icon={<PhoneIcon />} size="lg" /></a>
                        </SectionToolbar>
                    </SectionExpertContent>
                </SectionExpert>
            </Container>
        </Section>

        <Section xpt="30px" mpt="20px" pt="15px" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
            <Container maxWidth="100%" pl="3%" pr="3%">
                <SectionInnerWhite xpl="0" xpr="0" mpl="0" mpr="0"  pl="0" pr="0">
                    <AccordionLabel textAlign="left">You’ve Got Questions? We’ve Got Answers!</AccordionLabel>
                    <AccordionDesc><p>Find the answers to frequently asked questions here:</p></AccordionDesc>
                    <Accordions>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                            <Accordionsitems className={activeTab === index ? 'active' : ''} key={index}>
                                <AccordionsTitle onClick={() => handleAccodion(index)}>
                                How long does it take to get a building? <span></span>
                                </AccordionsTitle>
                                <AccordionsContent className="accordions-content">
                                    <AccordionsContentinner>
                                        <p>Approximately six weeks; with current market conditions, it may take up to eight weeks. Most importantly, our representatives will give you an accurate lead time, so that you can make adjustments to your property and your calendar.</p>
                                    </AccordionsContentinner>
                                </AccordionsContent>
                            </Accordionsitems>
                        ))}
                    </Accordions>
                </SectionInnerWhite>
            </Container>
        </Section>
    
    </Layout>
    )
}

export default MetalBuildingPage

export const query = graphql`
  query MetalBuildingPageQuery {
    allContentfulProduct {
      edges {
        node {
          id
          name
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
          }
          subCategory {
            name
          }
          sku
          roofType {
            name
          }
          width
          length
          height
          price
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          warranty {
            warranty
          }
          otherSpec {
            otherSpec
          }
        }
      }
    }
  }
`
