import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import ProductCard from "../ProductCard"
import CardType from "../CardType"
import CardStyle from "../CardStyle"
import {
  Nodata,
  NodataContainer,
  NodataMedia,
  NodataTitle,
  NodataDesc,
  NodataBg,
} from "../Nodata"

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
  ${BreakpointUp.lg`
        margin:0 -20px;
    `}
`
const LeftSidbar = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  display:none;
  ${BreakpointUp.md`
  display:block;
  `}
  ${BreakpointUp.lg`
        padding:0 20px;    
        flex: 0 0 25%;
        max-width: 25%;
    `}
  ${BreakpointUp.xl`
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    `}   
    strong {
    display: block;
    margin-bottom: 10px;
    color: #000;
  }
`
const MainContent = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  order: 1;
  ${BreakpointUp.lg`
        padding:0 20px;
        flex: 0 0 75%;
        max-width: 75%;
        order:0;
    `}
  ${BreakpointUp.xl`       
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`
const TabContentInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
  ${BreakpointUp.lg`
        margin:0 -20px;
    `}
`
const TabContent = styled.div`
  position: relative;
  width: 100%;
  order: 1;
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  transition: opacity 0.4s linear;
  padding: 0 10px;
  ${BreakpointUp.lg`
        flex: 0 0 75%;
        max-width: 75%;
        order:0;
        padding:0 20px;
    `}
  ${BreakpointUp.xl`
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`
const RightSidebar = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg`
        flex: 0 0 25%;
        max-width: 25%;
        padding:0 20px;
    `}
  ${BreakpointUp.xl`
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    `} 
    strong {
    display: block;
    margin-bottom: 10px;
    color: #000;
  }
`
const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const ProductListItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.xxl`
        flex: 0 0 50%;
        max-width: 50%;
    `}
`
const CardGroup = styled.div`
  div:hover {
    cursor: pointer;
  }
  ${BreakpointDown.lg`
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        margin:0 -10px;
        .card{
            min-width: 150px;
            margin-left: 10px;
            margin-right: 10px;


        }
    `}
`

function ProductListSection({ data, openQuoteModal }) {
  const [activeTab, setActiveTab] = useState("Metal Buildings")
  const [activeRoof, setActiveRoof] = useState("All")
  const [bestVisible, setBestVisible] = useState(true)
  const [betterVisible, setBetterVisible] = useState(true)
  const [goodVisible, setGoodVisible] = useState(true)

  const currentCategoryProduct = data.filter(
    item => item.node.category.name === "Metal Buildings"
  )
  const [currentProducts, setCurrentProducts] = useState(currentCategoryProduct)
  function handleTab(key) {
    setActiveTab(key)
  }

  useEffect(() => {
    const currentCategoryProduct = data.filter(
      item => item.node.category.name === activeTab
    )
    setCurrentProducts(currentCategoryProduct)
    const verticalRoofProduct = currentCategoryProduct.filter(
      item => item.node.roofType.name === "Vertical"
    )
    if (verticalRoofProduct.length === 0) {
      setBestVisible(false)
    } else {
      setBestVisible(true)
    }
    const aFrameRoofProduct = currentCategoryProduct.filter(
      item => item.node.roofType.name === "A-Frame"
    )
    if (aFrameRoofProduct.length === 0) {
      setBetterVisible(false)
    } else {
      setBetterVisible(true)
    }

    const regularRoofProduct = currentCategoryProduct.filter(
      item => item.node.roofType.name === "Regular"
    )
    if (regularRoofProduct.length === 0) {
      setGoodVisible(false)
    } else {
      setGoodVisible(true)
    }

    setActiveRoof("All")
  }, [activeTab, setActiveTab])

  useEffect(() => {
    const currentCategoryProduct = data.filter(
      item => item.node.category.name === activeTab
    )
    if (activeRoof !== "All") {
      const currentRoofProduct = currentCategoryProduct.filter(
        item => item.node.roofType.name === activeRoof
      )
      setCurrentProducts(currentRoofProduct)
    } else {
      setCurrentProducts(currentCategoryProduct)
    }
  }, [activeRoof, setActiveRoof])

  return (
    <MainPanel>
      <LeftSidbar>
        <div className="isSticky">
          <strong>Buildings Type</strong>
          <CardGroup>
            <CardType
              buildingtype="Metal Buildings"
              buildingtitle="Metal Buildings"
              isActive={activeTab === "Metal Buildings" && true}
              handleTab={() => handleTab("Metal Buildings")}
            />
            <CardType
              buildingtype="Metal Barns"
              buildingtitle="Barns"
              isActive={activeTab === "Metal Barns" && true}
              handleTab={() => handleTab("Metal Barns")}
            />
            <CardType
              buildingtype="Metal Garages"
              buildingtitle="Garages"
              isActive={activeTab === "Metal Garages" && true}
              handleTab={() => handleTab("Metal Garages")}
            />
            <CardType
              buildingtype="Metal Carports"
              buildingtitle="Carports"
              isActive={activeTab === "Metal Carports" && true}
              handleTab={() => handleTab("Metal Carports")}
            />
            {/*
            <CardType
              buildingtype="RV Covers"
              buildingtitle="RV Covers"
              isActive={activeTab === "RV Covers" && true}
              handleTab={() => handleTab("RV Covers")}
            />
            */}   
          </CardGroup>
        </div>
      </LeftSidbar>
      <TabContent className="tab-contant">
        <TabContentInner>
          <MainContent>
            <ProductList>
              {currentProducts.length > 0 ? (
                <>
                  {currentProducts.map((item, i) => (
                    <ProductListItem key={i}>
                      <ProductCard
                        data={item.node}
                        roof={item.node.roofType.name}
                        openQuoteModal={openQuoteModal}
                      />
                    </ProductListItem>
                  ))}
                </>
              ) : (
                <>
                  <Nodata>
                    <NodataBg>
                      <StaticImage
                        src="../../images/vecteezy-backgrond.jpg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="not-found-bg"
                      />
                    </NodataBg>
                    <NodataContainer>
                      <NodataMedia>
                        <StaticImage
                          src="../../images/coming-soon.png"
                          placeholder="blurred"
                          quality={95}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          alt="coming-soon"
                        />
                      </NodataMedia>
                      <NodataTitle>Products Coming Soon!</NodataTitle>
                      <NodataDesc>
                        <p>
                          We'll be showcasing amazing metal buildings soon, that
                          we know you'll love. Until then, please call us at{" "}
                          <a href="tel:8004407309" aria-label="Phone">
                            (800) 440-7309
                          </a>{" "}
                          to know more about our versatile metal buildings.
                        </p>
                      </NodataDesc>
                    </NodataContainer>
                  </Nodata>
                </>
              )}
            </ProductList>
          </MainContent>
          <RightSidebar>
            <div className="isSticky">
              <strong>Roof Style</strong>
              <CardGroup>
                {bestVisible && (
                  <div onClick={() => setActiveRoof("Vertical")}>
                    <CardStyle
                      roofStyle="Vertical Roof"
                      styleTitle="Best"
                      styleLead="Vertical Roof Style"
                    />
                  </div>
                )}
                {betterVisible && (
                  <div onClick={() => setActiveRoof("A-Frame")}>
                    <CardStyle
                      roofStyle="A-FRAME"
                      styleTitle="Better"
                      styleLead="A-FRAME Roof Style"
                    />
                  </div>
                )}
                {goodVisible && (
                  <div onClick={() => setActiveRoof("Regular")}>
                    <CardStyle
                      roofStyle="Regular Roof"
                      styleTitle="Good"
                      styleLead="Regular Roof Style"
                    />
                  </div>
                )}
              </CardGroup>
            </div>
          </RightSidebar>
        </TabContentInner>
      </TabContent>
    </MainPanel>
  )
}

export default ProductListSection
